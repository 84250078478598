import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetWatchListQuery, useDeleteMovieMutation } from '../actions/apiSlice';
import { Table, Button, Container, Row, Col, Dropdown } from 'react-bootstrap';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import CircularProgress from '@mui/material/CircularProgress';
import { Helmet } from 'react-helmet-async';

function WatchlistScreen() {
  const [deletingMovieId, setDeletingMovieId] = useState(null);
  const [showNotifications, setShowNotifications] = useState(false);
  const navigate = useNavigate();

  const navigateShow = (show) => {
    const query = `${show.name} ${show.year}`;
    navigate('/details/?q=' + query);
  };

  const { data, error, isLoading } = useGetWatchListQuery();
  const [deleteMovie] = useDeleteMovieMutation();

  function formatDate(date) {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const formattedDate = new Date(date).toLocaleDateString(undefined, options);
    const currentDate = new Date();
    const movieDate = new Date(date);

    let colorClass = '';
    if (movieDate < currentDate) {
      colorClass = 'text-success'; // Green if the movie date is before today
    } else {
      colorClass = 'text-danger'; // Red if the movie date is in the future
    }

    return <span className={colorClass}>{formattedDate}</span>;
  }

  const handleDelete = (movieId) => {
    setDeletingMovieId(movieId);
    deleteMovie(movieId).finally(() => setDeletingMovieId(null));
  };

  // ✅ Filter out movies/shows that are currently streaming
  const streamingNow = data ? data.filter(movie => movie.is_streaming) : [];

  return (
    <div>
      <Helmet>
        <title>Watchlist</title>
      </Helmet>

      {/* 🔔 Notification Bell */}
      <div style={{ position: 'absolute', top: '10px', right: '20px' }}>
        <NotificationsActiveIcon
          style={{ fontSize: '30px', cursor: 'pointer' }}
          onClick={() => setShowNotifications(!showNotifications)}
        />
        {streamingNow.length > 0 && (
          <span
            style={{
              position: 'absolute',
              top: '-5px',
              right: '-5px',
              backgroundColor: 'red',
              color: 'white',
              borderRadius: '50%',
              padding: '5px',
              fontSize: '12px'
            }}
          >
            {streamingNow.length}
          </span>
        )}
      </div>

      {/* 🔽 Notification Dropdown */}
      {showNotifications && (
        <div style={{
          position: 'absolute',
          top: '50px',
          right: '20px',
          backgroundColor: 'white',
          boxShadow: '0px 4px 8px rgba(0,0,0,0.2)',
          padding: '10px',
          borderRadius: '5px',
          zIndex: 1000,
          width: '300px'
        }}>
          <h5>Now Streaming 🎬</h5>
          {streamingNow.length === 0 ? (
            <p>No new streaming updates.</p>
          ) : (
            streamingNow.map(movie => (
              <div key={movie.id} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px',cursor: 'pointer' }}
              onClick={() => navigateShow(movie)}
              >
                <img
                  src={movie.small_poster_url}
                  alt={movie.name}
                  style={{ width: '50px', height: '75px', objectFit: 'cover', marginRight: '10px' }}
                />
                <p style={{ margin: 0 }}>{movie.name} is now streaming! 🍿</p>
              </div>
            ))
          )}
          <Button
            variant="secondary"
            size="sm"
            onClick={() => setShowNotifications(false)}
            style={{ width: '100%', marginTop: '10px' }}
          >
            Close
          </Button>
        </div>
      )}

      {isLoading ? (
        <Container>
          <Row className="justify-content-center align-items-center" style={{ height: '100vh' }}>
            <Col xs="auto">
              <CircularProgress size={50} />
            </Col>
          </Row>
        </Container>
      ) : error ? (
        <p>Error: {error.message}</p>
      ) : data ? (
        <Container>
          <Row>
            {/* Movies Table */}
            <Col xs={12} md={6}>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th colSpan="3" className="text-center">Movies</th>
                  </tr>
                  <tr>
                    <th>Name</th>
                    <th>Release Date</th>
                    <th></th> {/* Empty cell for Delete button */}
                  </tr>
                </thead>
                <tbody>
                  {data.filter(movie => movie.is_movie).map(movie => (
                    <tr key={movie.id}>
                      <td onClick={() => navigateShow(movie)} style={{ cursor: 'pointer' }}>
                        <img
                          src={movie.small_poster_url}
                          alt={movie.name}
                          style={{ width: '72px', height: '106px', objectFit: 'cover' }}
                        />
                        {movie.name}
                      </td>
                      <td>{formatDate(movie.air_date)}</td>

                      <td>
                        {deletingMovieId === movie.id ? (
                          <CircularProgress size={24} />
                        ) : (
                          <RemoveCircleOutlineOutlinedIcon
                            onClick={() => handleDelete(movie.id)}
                            style={{ cursor: 'pointer' }}
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>

            {/* Non-Movies Table */}
            <Col xs={12} md={6}>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th colSpan="2" className="text-center">TV Shows</th>
                  </tr>
                  <tr>
                    <th>Name</th>
                    <th></th> {/* Empty cell for Delete button */}
                  </tr>
                </thead>
                <tbody>
                  {data.filter(movie => !movie.is_movie).map(movie => (
                    <tr key={movie.id}>
                      <td onClick={() => navigateShow(movie)} style={{ cursor: 'pointer' }}>
                        <img
                          src={movie.small_poster_url}
                          alt={movie.name}
                          style={{ width: '72px', height: '106px', objectFit: 'cover' }}
                        />
                        {movie.name}
                      </td>
                      <td>
                        {deletingMovieId === movie.id ? (
                          <CircularProgress size={24} />
                        ) : (
                          <RemoveCircleOutlineOutlinedIcon
                            onClick={() => handleDelete(movie.id)}
                            style={{ cursor: 'pointer' }}
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      ) : null}
    </div>
  );
}

export default WatchlistScreen;
