import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';
import { redirectToLogin }from '../hooks/utilityRedirectLogin';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: (headers, { endpoint }) => {
    const token = localStorage.getItem('aircheck_access'); // Retrieve the access token
    if (
      token &&
      !['login', 'register', 'searchShow'].includes(endpoint)
    ) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    headers.set('Content-Type', 'application/json');
    return headers;
  },
});

// Wrapper to handle token refresh
const baseQueryWithRefresh = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  // Check if access token is expired (401 Unauthorized)
  if (result.error && result.error.status === 401) {
    console.log("Access token expired. Attempting to refresh...");

    // Try refreshing the access token
    const refreshToken = localStorage.getItem('aircheck_refresh'); // Retrieve the refresh token


    if (refreshToken) {
      const refreshResult = await baseQuery(
        {
          url: '/api/token/refresh/',
          method: 'POST',
          body: { refresh: refreshToken },
        },
        api,
        extraOptions
      );

      if (refreshResult.data) {
        // Update access token in local storage
        const newAccessToken = refreshResult.data.access;
        localStorage.setItem('aircheck_access', newAccessToken);

        // Retry the original request with the new access token
        result = await baseQuery(args, api, extraOptions);
      } else {
        console.error("Failed to refresh token. Logging out...");
        // Handle logout or token invalidation
        localStorage.removeItem('aircheck_access');
        localStorage.removeItem('aircheck_refresh');
      }
    } else {
      console.error("No refresh token available. Logging out...");
      // Handle logout or token invalidation
      localStorage.removeItem('aircheck_access');
      localStorage.removeItem('aircheck_refresh');
    }
  }

  return result;
};
export const api = createApi({
  baseQuery: baseQueryWithRefresh, // Use the enhanced baseQuery
  endpoints: (build) => ({
    getWatchList: build.query({
      query: () => '/api/v1/movies/',
      providesTags: ['watchlist', 'searchTrailers'],
    }),
    addList: build.mutation({
      query: (data) => ({
        url: '/api/v1/movies/',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['watchlist'],
    }),
    deleteMovie: build.mutation({
      query: (deletedMovieId) => ({
        url: `/api/v1/movies/${deletedMovieId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['watchlist'],
    }),
    searchShow: build.query({
      query: (query) => `/api/v1/search-movie/?q=${query}`,
    }),
    funFacts: build.query({
      query: ({ name, year, kind }) =>
        `/api/v1/fun-facts/?name=${name}&year=${year}&kind=${kind}`,
    }),
    reminder: build.query({
      query: () => '/api/v1/tvseries/',
      providesTags: ['reminders'],
    }),
    addReminder: build.mutation({
      query: (data) => ({
        url: '/api/v1/preference/',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['reminders'],
    }),
    addFcm: build.mutation({
      query: (data) => ({
        url: '/api/v1/fcm-token/',
        method: 'POST',
        body: data,
      }),
    }),
    deleteReminder: build.mutation({
      query: (id) => ({
        url: `/api/v1/tvseries/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['reminders'],
    }),
    episodeAvailability: build.mutation({
      query: (data) => ({
        url: '/api/v1/aircheck/',
        method: 'POST',
        body: data,
      }),
    }),
    profile: build.query({
      query: () => '/api/auth/user/',
      providesTags: ['profile'],
    }),
  }),
});

export const {
  useGetWatchListQuery,
  useDeleteMovieMutation,
  useSearchShowQuery,
  useReminderQuery,
  useDeleteReminderMutation,
  useAddReminderMutation,
  useAddFcmMutation,
  useEpisodeAvailabilityMutation,
  useAddListMutation,
  useFunFactsQuery,
  useProfileQuery,
} = api;
