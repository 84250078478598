import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const YOUTUBE_KEY = process.env.REACT_APP_YOUTUBE_API_KEY;


export const youtubeApi = createApi({
  reducerPath: 'youtubeApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://www.googleapis.com',
  }),
  endpoints: (builder) => ({
    searchTrailers: builder.query({
      query: ({ query, kind, year }) =>
        `/youtube/v3/search?part=snippet&maxResults=1&q=${query} ${kind} ${year} trailer&type=video&key=${YOUTUBE_KEY}`,
      providesTags: ['searchTrailers'],
    }),
    searchEpisodePromos: builder.query({
      query: ({ query, seasonNumber, episodeNumber }) =>
        `/youtube/v3/search?part=snippet&maxResults=1&q=${query} season ${seasonNumber} episode ${episodeNumber} trailer&type=video&key=${YOUTUBE_KEY}`,
      providesTags: ['searchEpisodePromos'],
    }),
  }),
});

export const { useLazySearchTrailersQuery, useLazySearchEpisodePromosQuery } = youtubeApi;